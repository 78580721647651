exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-blog-styles-js": () => import("./../../../src/pages/blog/blog.styles.js" /* webpackChunkName: "component---src-pages-blog-blog-styles-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-letter-from-the-ceo-jsx": () => import("./../../../src/pages/letter-from-the-ceo.jsx" /* webpackChunkName: "component---src-pages-letter-from-the-ceo-jsx" */),
  "component---src-pages-opiniones-clientes-jsx": () => import("./../../../src/pages/opiniones-clientes.jsx" /* webpackChunkName: "component---src-pages-opiniones-clientes-jsx" */),
  "component---src-pages-reports-jsx": () => import("./../../../src/pages/reports.jsx" /* webpackChunkName: "component---src-pages-reports-jsx" */),
  "component---src-pages-russian-gas-dependency-jsx": () => import("./../../../src/pages/russian-gas-dependency.jsx" /* webpackChunkName: "component---src-pages-russian-gas-dependency-jsx" */),
  "component---src-pages-technicians-jsx": () => import("./../../../src/pages/technicians.jsx" /* webpackChunkName: "component---src-pages-technicians-jsx" */),
  "component---src-pages-terms-and-conditions-cookie-policy-jsx": () => import("./../../../src/pages/terms-and-conditions/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-cookie-policy-jsx" */),
  "component---src-pages-terms-and-conditions-legal-notice-jsx": () => import("./../../../src/pages/terms-and-conditions/legal-notice.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-legal-notice-jsx" */),
  "component---src-pages-terms-and-conditions-privacy-policy-jsx": () => import("./../../../src/pages/terms-and-conditions/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-privacy-policy-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-tld-category-jsx": () => import("./../../../src/templates/tld-category.jsx" /* webpackChunkName: "component---src-templates-tld-category-jsx" */),
  "component---src-templates-tld-page-jsx": () => import("./../../../src/templates/tld-page.jsx" /* webpackChunkName: "component---src-templates-tld-page-jsx" */),
  "component---src-templates-tld-success-stories-jsx": () => import("./../../../src/templates/tld-success-stories.jsx" /* webpackChunkName: "component---src-templates-tld-success-stories-jsx" */),
  "component---src-templates-tld-success-story-jsx": () => import("./../../../src/templates/tld-success-story.jsx" /* webpackChunkName: "component---src-templates-tld-success-story-jsx" */)
}

