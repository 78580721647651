module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sunhero | Solarpower for everyone!","short_name":"Sunhero","start_url":"/","background_color":"#ffffff","display":"standalone","include_favicon":false,"icon":"src/images/sunhero_favicon_256.webp","icons":[{"src":"icon-16x16.ico","sizes":"16x16","type":"image/ico"},{"src":"icon-32x32.ico","sizes":"32x32","type":"image/ico"},{"src":"icon-48x48.ico","sizes":"48x48","type":"image/ico"},{"src":"icon-72x72.ico","sizes":"72x72","type":"image/ico"},{"src":"icon-96x96.ico","sizes":"96x96","type":"image/ico"},{"src":"icon-144x144.ico","sizes":"144x144","type":"image/ico"},{"src":"icon-192x192.ico","sizes":"192x192","type":"image/ico"},{"src":"icon-256x256.ico","sizes":"256x256","type":"image/ico"},{"src":"icon-384x384.ico","sizes":"384x384","type":"image/ico"},{"src":"icon-512x512.ico","sizes":"512x512","type":"image/ico"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blog-api.sunhero.com/graphql","schema":{"typePrefix":"Wp","timeout":180000,"queryDepth":15,"circularQueryLimit":5,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"type":{"Post":{"limit":5000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"redirect":false,"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://www.sunhero.com","i18nextOptions":{"ns":["translations","whysolar","contactus"],"defaultNS":"translations","debug":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":true},{"matchPath":"/:lang?/:uid","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/:firstLevelSlug?/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
